import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, navigate } from 'gatsby';
import { TextField, Button, CircularProgress } from '@material-ui/core';
import validator from 'validator';
import qs from 'query-string';

import { validatePassword } from '../utils/account-utils';

import SEO from '../components/seo';

import * as AccountActions from '../actions/account-actions';
import * as Errors from '../constants/errors';

import styles from '../styles/login.module.scss';

const ResetPasswordPage = ({ location, resetPassword }) => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [errors, setErrors] = useState(null);
    const [inputErrors, setInputErrors] = useState({});
    const [values, setValues] = useState(
        {
            password: ''
        }
    );

    const handleChange = (e) => {
        if (e.target) {
            const { name, value } = e.target;

            setValues(state => ({
                ...state,
                [name]: value
            }));

            if (inputErrors[name]) {
                setInputErrors(state => ({
                    ...state,
                    [name]: null
                }));
            }
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        const { password } = values;

        const errs = {};

        if (validator.isEmpty(password)) {
            errs['password'] = 'Password Required';
        } else {
            const passErr = validatePassword(password);

            if (passErr) {
                errs['password'] = passErr;
            }
        }

        if (Object.keys(errs).length) {
            setInputErrors(errs);
            return false;
        }

        const { token } = qs.parse(location.search);

        if (!token) {
            setErrors(
                [
                    'Sorry, this password reset link is invalid.',
                    'Please use the forgot password form to send a new link.'
                ]
            );
            return false;
        }

        setLoading(true);
        setSuccess(null);
        setErrors(null);

        const result = await resetPassword(
            {
                token,
                password
            }
        );

        setLoading(false);

        if (result.ok) {
            setSuccess(['Your password has been reset succesfully.']);

            navigate(
                '/my-account/',
                {
                    replace: true
                }
            );

            return true;
        }

        if (!result.error) {
            setErrors([
                'Sorry, an error occurred.',
                'Please try again.'
            ]);
            return false;
        }

        const { type } = result.error;

        switch (type) {
            case Errors.INVALID_TOKEN:
                setErrors(
                    [
                        'Sorry, this password reset link is expired.',
                        'Please use the forgot password form to send a new link.'
                    ]
                );
                return false;

            default:
                setErrors([
                    'Sorry, an error occurred.',
                    'Please try again.'
                ]);
                return false;
        }
    };

    return (
        <>
            <SEO
                title='Reset Password'
                path='/reset-password/'
                description='Reset your Roselium password to login to your account.'
            />
            <section className={styles.main}>
                <div className={styles.content}>
                    <form
                        noValidate
                        onSubmit={onSubmit}
                    >
                        <h1>Reset Password</h1>
                        <p>Please enter your new password.</p>
                        {success &&
                            <ul className={styles.success}>
                                {success.map((x, i) => (
                                    <li key={i}>{x}</li>
                                ))}
                            </ul>
                        }
                        {errors &&
                            <ul className={styles.errors}>
                                {errors.map((x, i) => (
                                    <li key={i}>{x}</li>
                                ))}
                            </ul>
                        }
                        <TextField
                            id='password'
                            name='password'
                            label={inputErrors['password'] || 'Password'}
                            error={Boolean(inputErrors['password'])}
                            type='password'
                            autoComplete='new-password'
                            value={values.password}
                            onChange={handleChange}
                            margin='normal'
                            fullWidth={true}
                            className={styles.textField}
                        />
                        <Button
                            type='submit'
                            variant='contained'
                            color='primary'
                            fullWidth={true}
                            className={styles.signin}
                            disabled={loading}
                        >
                            {loading ?
                                <CircularProgress
                                    color='inherit'
                                    size={24}
                                    className={styles.loading}
                                /> :
                                'SUBMIT'
                            }
                        </Button>
                        <Button
                            type='button'
                            variant='text'
                            component={Link}
                            to='/forgot-password/'
                            size='small'
                            color='secondary'
                            className={styles.forgot}
                        >
                            Re-send Email
                        </Button>
                    </form>
                </div>
            </section>
        </>
    )
};

const mapDispatch = { resetPassword: AccountActions.resetPassword };

export default connect(null, mapDispatch)(ResetPasswordPage);
